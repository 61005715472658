<template>
  <div>
    <v-dialog v-model="dialog" width="500" persistent>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="success"
          class="primary--text my-4"
          dark
          v-bind="attrs"
          v-on="on"
        >
          + New recruitment
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="d-flex text-h5 primary white--text">
          New recruitment
          <v-spacer></v-spacer>
          <v-btn color="white" icon @click="close"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <p class="px-8 pt-8 mb-1" style="font-size: 12px;">Details:</p>
        <div class="px-8 mb-2 ">
          <v-text-field
            label="Title"
            class="mb-2"
            v-model="recruitment.title"
            outlined
            hide-details=""
            background-color="grey lighten-4"
          ></v-text-field>

          <div class="d-flex mb-2" style="gap: 10px;">
            <v-text-field
              label="Duration (minutes)"
              v-model="recruitment.duration"
              outlined
              type="number"
              hide-details=""
              background-color="grey lighten-4"
            ></v-text-field>
            <v-text-field
              label="Incentive (€)"
              v-model="recruitment.incentive"
              outlined
              type="number"
              hide-details=""
              background-color="grey lighten-4"
            ></v-text-field>
          </div>
          <v-autocomplete
            class="mb-2"
            hide-details=""
            outlined
            multiple
            item-text="name"
            item-value="uid"
            background-color="grey lighten-4"
            :items="$store.state.tests"
            label="Tests"
            v-model="recruitment.tests"
          ></v-autocomplete>
          <v-select
            hide-details=""
            outlined
            class="mb-2"
            background-color="grey lighten-4"
            :items="['BraingiLab1', 'BraingiLab2', 'Dentsu Lab']"
            label="Lab"
            v-model="recruitment.lab"
          ></v-select>
          <div class="three mb-2">
            <v-select
              hide-details=""
              outlined
              background-color="grey lighten-4"
              :items="['paused', 'active', 'finished']"
              label="Status"
              v-model="recruitment.status"
            ></v-select>
            <v-select
              hide-details=""
              outlined
              background-color="grey lighten-4"
              :items="['normal', 'fast']"
              label="Speed"
              v-model="recruitment.speed"
            ></v-select>
            <v-select
              hide-details=""
              outlined
              background-color="grey lighten-4"
              item-value="uid"
              item-text="title"
              :items="$store.state.recruitments"
              label="Filter recruitment invites"
              multiple
              v-model="recruitment.filter_rec"
            ></v-select>
          </div>
          <v-text-field
            v-model="recruitment.location"
            label="Location"
            outlined
            hide-details=""
            background-color="grey lighten-4"
          ></v-text-field>
        </div>

        <div class="px-8">
          <p class=" mt-4 mb-1" style="font-size: 12px;">Participants:</p>

          <div class="three">
            <v-text-field
              label="Min. amount"
              v-model="recruitment.min_pp"
              outlined
              type="number"
              hide-details=""
              background-color="grey lighten-4"
            ></v-text-field>
            <v-text-field
              prepend-inner-icon="mdi-face-woman"
              label="Min. female"
              v-model="recruitment.min_female"
              outlined
              type="number"
              hide-details=""
              background-color="grey lighten-4"
            ></v-text-field>
            <v-text-field
              label="Min. male"
              prepend-inner-icon="mdi-face-man"
              v-model="recruitment.min_male"
              outlined
              type="number"
              hide-details=""
              background-color="grey lighten-4"
            ></v-text-field>
          </div>
        </div>

        <p class="px-8 mt-4 mb-1" style="font-size: 12px;">Timeslots:</p>
        <div
          class="slots px-8 mb-2"
          style="gap: 10px"
          v-for="(d, index) in recruitment.slots"
          :key="index"
        >
          <v-menu
            ref="menu"
            v-model="menu[index]"
            transition="scale-transition"
            offset-y
            min-width="auto"
            :key="index"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :value="d.day"
                label="Day"
                outlined
                background-color="grey lighten-4"
                readonly
                hide-details=""
                v-bind="attrs"
                v-on="on"
                :key="index"
              ></v-text-field>
            </template>
            <v-date-picker
              @change="changed_slots = true"
              v-model="d.day"
              no-title
              scrollable
            >
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menu = false">
                Cancel
              </v-btn>
              <v-btn text color="primary" @click="menu = false">
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
          <v-select
            @change="changed_slots = true"
            hide-details=""
            outlined
            background-color="grey lighten-4"
            :items="times"
            label="Start time"
            v-model="d.start"
          ></v-select>
          <v-select
            @change="changed_slots = true"
            hide-details=""
            outlined
            background-color="grey lighten-4"
            :items="times"
            label="End time"
            v-model="d.end"
          ></v-select>
          <v-btn
            icon
            class="ml-1 pr-2"
            @click="
              recruitment.slots.splice(index, 1);
              changed_slots = true;
            "
            ><v-icon>mdi-delete</v-icon></v-btn
          >
        </div>

        <div class="px-8 mb-2">
          <v-btn small @click="addSlot()" color="secondary" outlined
            >Add timeslot</v-btn
          >
        </div>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="success"
            class="primary--text"
            elevation="0"
            @click="save"
            :loading="loading"
          >
            {{ recruitment.uid ? "Save" : "Recruit!" }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { db, auth } from "../firebase";
import moment from "moment";
import firebase from "firebase";

export default {
  props: ["selected"],
  watch: {
    selected(val) {
      console.log(val);
      if (val && val.uid) {
        this.recruitment = JSON.parse(JSON.stringify(val));
        this.existing_slots = val.slots.length;
        this.dialog = true;
      }
    },
  },
  data() {
    return {
      loading: false,
      changed_slots: false,
      dialog: false,
      menu: [],
      times: [
        "09:00",
        "09:30",
        "10:00",
        "10:30",
        "11:00",
        "11:30",
        "12:00",
        "12:30",
        "13:00",
        "13:30",
        "14:00",
        "14:15",
        "14:30",
        "14:45",
        "15:00",
        "15:30",
        "16:00",
        "16:30",
        "17:00",
        "17:30",
        "17:45",
        "18:00",
        "18:30",
        "19:00",
        "19:30",
        "20:00",
        "20:45",
        "21:45",
      ],

      existing_slots: 0,
      recruitment: {
        status: "active",
        duration: null,
        incentive: null,
        tests: [],
        slots: [
          {
            day: "",
            start: "",
            end: "",
          },
        ],
        min_pp: 10,
        min_female: 0,
        filter_rec: [],
        min_male: 0,
        lab: "",
        backup_pp: [],
        speed: "",
        new_slots: false,
        pp_female: 0,
        out_of_pp: false,
        pp_male: 0,
        pp_total: 0,
        title: "",
        location:
          "Braingineers (Let op: nu 3e verdieping), Johan Huizingalaan 763A, Amsterdam",
        invited: [],
        scheduled: [],
        batch: [],
        batch_update: null,
      },
    };
  },
  methods: {
    addSlot() {
      this.recruitment.slots.push({
        day: "",
        start: "",
        end: "",
      });
      this.changed_slots = true;
    },
    close() {
      this.$emit("close");
      this.dialog = false;
      this.changed_slots = false;
      this.recruitment = {
        duration: null,
        incentive: null,
        tests: [],
        batch: [],
        slots: [
          {
            day: "",
            start: "",
            end: "",
          },
        ],
        min_pp: 10,
        min_female: 0,
        min_male: 0,
        lab: "",
        filter_rec: [],
        speed: "normal",
        backup_pp: [],
        pp_female: 0,
        pp_male: 0,
        pp_total: 0,
        out_of_pp: false,
        new_slots: false,
        scheduled: [],
        batch_update: null,
        title: "",
        location: "Braingineers, Johan Huizingalaan 763A, Amsterdam",
        invited: ["PLSyaOL8lrRQbMsdAPISaCN7U4q1"],
        status: "active",
      };
    },
    generateSlots(rec) {
      return new Promise(async (resolve, reject) => {
        let events = [];
        let slots = rec.slots;

        for (let x in slots) {
          let current = new Date(`${slots[x].day}T${slots[x].start}`);

          let end = new Date(`${slots[x].day}T${slots[x].end}`);
          end.setMinutes(end.getMinutes() + 1);
          let next = new Date(current);
          next.setMinutes(next.getMinutes() + Number(rec.duration));

          while (next < end) {
            events.push({
              start: moment(new Date(current)).format(),
              end: moment(new Date(next)).format(),
              participant: {
                filled: null,
                uid: null,
                accepted: null,
              },
            });

            current.setMinutes(current.getMinutes() + Number(rec.duration));
            next.setMinutes(next.getMinutes() + Number(rec.duration));
          }
        }

        resolve(events);
      });
    },
    async save() {
      this.loading = true;
      const vm = this;

      let slots = [];

      if (this.recruitment.uid) {
        if (this.changed_slots) {
          let newSlots = await this.generateSlots(this.recruitment);

          var changeSlots = firebase.functions().httpsCallable("changeSlots");
          const res = await changeSlots({
            slots: newSlots,
            rec: this.recruitment.uid,
            original_slots: this.recruitment.slots,
          });
          console.log(res);
          if (res.data === 500) {
            alert(
              "Some of the slots you removed already had a participant scheduled. Please cancel the participant before adjusting the slots. The new slots are not saved."
            );
          }
        }

        await db
          .collection("recruitments")
          .doc(this.recruitment.uid)
          .update({
            duration: Number(this.recruitment.duration),
            incentive: Number(this.recruitment.incentive),
            min_pp: Number(this.recruitment.min_pp),
            min_female: Number(this.recruitment.min_female),
            min_male: Number(this.recruitment.min_male),
            lab: this.recruitment.lab,
            location: this.recruitment.location,
            speed: this.recruitment.speed,
            tests: this.recruitment.tests,
            title: this.recruitment.title,
            status: this.changed_slots ? "active" : this.recruitment.status,
          });
      } else {
        slots = await this.generateSlots(this.recruitment);

        this.recruitment.duration = Number(this.recruitment.duration);
        this.recruitment.incentive = Number(this.recruitment.incentive);
        this.recruitment.min_pp = Number(this.recruitment.min_pp);
        this.recruitment.min_female = Number(this.recruitment.min_female);
        this.recruitment.min_male = Number(this.recruitment.min_male);

        const recDoc = await db
          .collection("recruitments")
          .add(this.recruitment);

        await db
          .collection("recruitments")
          .doc(recDoc.id)
          .update({
            uid: recDoc.id,
            created_at: new Date(),
            created_by: this.$store.state.userProfile.uid,
          });

        // Add slots documents
        slots.forEach(async (s) => {
          let slot = await db
            .collection("recruitments")
            .doc(recDoc.id)
            .collection("slots")
            .add(s);

          await db
            .collection("recruitments")
            .doc(recDoc.id)
            .collection("slots")
            .doc(slot.id)
            .update({
              uid: slot.id,
            });
        });
      }

      // Fetch recruitments & reset new recruitment & close dialog
      vm.$store.dispatch("fetchRecruitments");
      vm.recruitment = {
        duration: null,
        incentive: null,
        tests: [],
        batch: [],
        slots: [
          {
            day: "",
            start: "",
            end: "",
          },
        ],
        min_pp: 10,
        min_female: 0,
        min_male: 0,
        lab: "",
        new_slots: false,
        scheduled: [],
        speed: "normal",
        backup_pp: [],
        pp_female: 0,
        pp_male: 0,
        out_of_pp: false,
        filter_rec: [],
        pp_total: 0,
        batch_update: null,
        title: "",
        location: "Braingineers, Johan Huizingalaan 763A, Amsterdam",
        invited: ["PLSyaOL8lrRQbMsdAPISaCN7U4q1"],
        status: "active",
      };
      this.loading = false;
      vm.dialog = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.slots {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 40px;
  align-items: center;
}
.two {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  gap: 10px;
}
.three {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  gap: 10px;
}
</style>
