import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/analytics";
import "firebase/functions";

// production

const firebaseConfig = {
  apiKey: "AIzaSyAh3tq3cjOLSeZ775pIAeci4-EWUXP0RZI",
  authDomain: "research-856bc.firebaseapp.com",
  databaseURL: "https://research-856bc.firebaseio.com",
  projectId: "research-856bc",
  storageBucket: "research-856bc.appspot.com",
  messagingSenderId: "952093088921",
  appId: "1:952093088921:web:3b11624b3e58d5d860ac08",
  measurementId: "G-MDN7VMNVGW",
};

// staging

// const firebaseConfig = {
//   apiKey: "AIzaSyDbOTCWO1zEv2PiNfeFHbUvisa3_7hZjYk",
//   authDomain: "panel-braingineers.firebaseapp.com",
//   projectId: "panel-braingineers",
//   storageBucket: "panel-braingineers.appspot.com",
//   messagingSenderId: "911541724490",
//   appId: "1:911541724490:web:6e2a9ae2b8f5dba810cdb3",
// };

// turn off analytics when using staging
firebase.initializeApp(firebaseConfig);
firebase.analytics();
// firebase.functions();
// firebase.functions().useEmulator("localhost", 5001);

// utils
const db = firebase.firestore();
const auth = firebase.auth();

// export utils/refs
export { db, auth };
